.csUser-list {
  .list-item{
    padding:0.2rem 0;
    &:last-child{
      padding-bottom: 0;
    }
    .user-pic {
      margin-right:0.4rem;
      display: inline-block;
      width: 30px;
      border: @lightGrey solid 1px;
    }
    .rating {
     margin: 5px 0;
      font-size: 0.9rem;
      direction: rtl;
    }
    .csUser-name {
      max-width: ~"calc(100% - 160px)";
      .text-overflow();
      line-height: 30px;
    }
    .gh-count__grey{
      margin-right: 0.5rem;
    }
  }
}

/* ---- Rating Progress Bar Custom color Start---- */

.cs_rating-progress-bar[value]::-webkit-progress-value {
  background-color: @grey;
  .round-borders(0px);
}

.cs_rating-progress-bar[value]::-moz-progress-bar {
  background-color: @grey;
  border-radius: 0px;
}

.cs_rating-progress-bar[value]::-ms-fill {
  background-color: @grey;
  .round-borders(0x);
}

/* ---- Rating Progress Bar Custom color End---- */

/* ==================  overWrite start  ============================*/

aside {
  .card-wrapper {
    height: 300px;
    box-shadow: none;
    perspective: 1000px;
    .back {
      .card-rating {
        .rotate-btn {
          .fa {
            font-size: 1rem;
          }
        }
        .progress {
          height: 12px;
          margin:0.4rem 0 0.74rem 0;
        }
      }
    }
  }
}
/* ---- Rating Star color Start---- */
.rating {
  color: @grey !important;
  &.rating-active {
    li:hover {
      color: @gold;
      cursor: pointer;
      & ~ li {
        color: @gold;
      }
    }
  }
  &.rating-disable {
    &:hover {
      .fa {
        color: @grey;
      }
    }
  }
}
/* ---- Rating Star color Start---- */