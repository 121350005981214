.csGeo {
  .clear;

  .msg-error {
    margin-bottom: 10px;
  }

  .csGeo__canvas {
    width: 100%;
    height: 400px;
    background-color: #fff;
    backface-visibility: visible;
    position:relative;
    border: 1px @lightGrey solid;
    z-index: 99;
    .box-sizing;

    .status {
      margin: 55px 0 0 0;
      font-size: 16px;
      line-height: 1.1em;
      z-index: 99999;
      position: relative;
    }

    &.waiting {
      height: 100px;
      border: 1px @btnGrey solid;
      background: #fff;
      text-align: center;
      .box-sizing;
      .js-loading {
        display: block;
        span {
          margin-top: 25px;
        }
      }
    }

    .js-loading {
      display: none;
    }
  }
  .csGeo__infowindow__wrap {
    width: 350px;
    max-width:100%;
  }
  .csGeo__infowindow {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
    border-bottom: 1px @btnGrey solid;
    padding: 0 0 10px 0;

    p {
      padding: 0;
    }
    .icon {
      font-size: 18px;
    }

    .placeholder {
      border: 1px @btnGrey solid;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      .round-borders(100%);

      .placeholder__icon:before {
        font-size: 30px;
        line-height: 65px;
        color: #505050;
        margin-left: 2px;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }


    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &.multiple:last-of-type{
      margin-bottom: 10px;
    }

    .csGeo__infoItem {
      >a {
        position: relative;
        z-index: 2;
        display: block;
        float: left;
        margin: 0 15px 0 0;
      }

      &.user {
        img {
          .round-borders(100%);
          border: 1px @btnGrey solid;
        }
      }
    }

    .preview {
      width: 70px;
      height: auto;
      float: left;
      margin: 0;
      border: 1px @btnGrey solid;
    }

    .title {
      font-size: 16px;
      margin-bottom: 10px;

      a {
        line-height: 1.1em;
        color: black;

      }
    }
    .desc{
      font-size: 14px;
      line-height: 1.2em;

    }
    .content {
      float: left;
      width: 100%;
      margin-left: -100px;
      padding-left: 100px;
      .box-sizing;
      position: relative;
      z-index: 1;
    }


  }
}
#map-canvas-single {
  width: 100%;
  height: 359px;
  background-color: #CCC;
  border: 1px @btnGrey solid;
}

.csGeo__msg {
  float: left;
  width: 100%;
  display: none;
}

.latlongmap {
  width: 100%;
  height: 400px;
  background-color: #CCC;
}

.csGeo__canvas {
  .gm-style .gm-style-iw, .gm-style .gm-style-iw a, .gm-style .gm-style-iw span, .gm-style .gm-style-iw label, .gm-style .gm-style-iw div {
    max-height: 280px;
    overflow: hidden;
  }
  .gm-style-iw {
    overflow-y: auto!important;
  }
  .gm-style-iw div {
    overflow:visible !important;
  }
}

.csGeo #map-canvas-single {
  border: none;
}