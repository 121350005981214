.csProductSlider {
  position: relative;

  .csProductSlider__inner {
    display: none;
    &.csProductSlider--mobile {
      display: block;
    }
  }

  .carousel-indicators li {
    border-color: #d8d8d8
  }

  .carousel-control.right {
    background: transparent;
    right: -25px;
    width: 60px;
  }

  .carousel-control.left {
    background: transparent;
    left: -25px;
    width: 60px;
  }

  .carousel-item .row {
    .flex-display;
    .flex-wrap(wrap);
    margin-left: -10px;
    margin-right: -10px;

    .col-md-3 {
      float: none;
      .flex(0 0 100%);
      width: auto;
      padding-right: 10px;
      padding-left: 10px;
      margin-bottom: 20px;
    }
  }

  .card {
    border: 1px #e5e5e5 solid;
    box-shadow: none;
    height: 100%;

    .card-top {
      position: relative;
      height: auto;
      padding-top: 100%;

      a {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        .flex-display();
        .flex-direction(column);
        .justify-content(center);
        height: 100%;

        img.img-landscape {
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }

    img {
      margin: 0 0 10px 0
    }

    .card-title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 6px;
      min-height: 50px;
    }

    .list-inline li {
      margin: 0!important;

      span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        vertical-align: top;
      }
    }
    .list-inline li i {
      color: #f1d81c;
    }

    .price {
      color: #b32110;
      font-weight: 600;
      margin-top: 5px;
    }

  }

  .csProductSlider--mobile .row {
    display: block;
  }

}

@media screen and(min-width:480px) {
  .csProductSlider {
    .carousel-item .row {
      .flex-wrap(wrap);
      .col-md-3 {
        .flex(0 0 50%);
      }
    }
  }
}

@media screen and(min-width:768px) {
  .csProductSlider {
    .carousel-item .row {
      .flex-wrap(nowrap);
      .col-md-3 {
        .flex(0 0 25%);
        margin-bottom: 0;
      }
    }

     .csProductSlider__inner{
      display: block;
    }
    .csProductSlider--mobile {
      display: none!important;
    }
  }
}
