.csMemory {
  width: auto;
  .csMemory__cards {
    z-index: 1;
    margin-top: 20px;

    div div.inner{
      border: 1px solid #C3C3C3;
      cursor: pointer;
      .round-borders(2px);
      background: #E0E0E0;
      z-index: 2;
      position: relative;
      img {
        display: none;
        border-radius: 2px;
        z-index: 3;
        width: 100%;
        height: 100%;
      }
      img.csMemory__cover{
        display: block;
      }
      img.csMemory__src {
        position: absolute;
        top: 0;
        background: #fff;
      }
    }
  }
}