
.csCarousel-media {
  .carousel-inner {
    .carousel-item {
      min-height: 100%;
      .carouselImg {
        margin: 0px auto;
        float: none !important;
        width: 39%;
        position: relative;
        max-height: 400px;
        span {
          position: absolute;
          bottom: -30px;
          left: 0px;
        }
      }
    }
  }
  .carousel-control {
    width: 30px;
    &.left {
      background-image: none;
    }
    &.right {
      background-image: none;
    }
  }
  .carousel-indicators {
    li {
      float: left;
      margin: 0px;
      .thumb {
        display: inline-block;
        float: left;
        text-align: right;
        position: relative;
        span {
          position: absolute;
          bottom: -30px;
          left: 10px;
          font-size: 14px;

        }
      }

    }
  }
}
.csCarousel-media-pdf{
   .indicatorWrapper{
     .carousel-indicators {
       li{
         float: none !important;
       }
     }

   }

}

.csCarousel-media-issue {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: visible !important;
  margin: auto;
  margin-bottom: 200px;
  .carousel-control {
    width: 5%;
    &.left {
      background-image: none;
    }
    &.right {
      background-image: none;
    }
  }
  .carousel-inner {
    width: 566px;
    margin: auto;
    max-width: 100%;
    .pull-right {
      float: right !important;
    }
    .pull-left {
      float: left !important;
    }

    .carousel-item {
      span {
        display: inline-block;
        float: left;
        width: 50%;
        text-align: right;
        &:first-child {
          text-align: left;
        }
        img {
          width: 100% !important;
          max-height: 400px !important;
          float: left;
        }
        .text-xs-right {
          width: 100%;
        }
      }
    }
  }
  .indicatorWrapper {
    width: 100%;
    position: relative;
    float: left;
    height: 200px;
    margin-top: 10px;
    white-space: nowrap;
    .carousel-indicators {
      position: relative;
      bottom: 0px;
      display: inline-block;
      height: 200px;
      overflow-x: hidden;
      overflow-y: hidden;
      li {
        margin-right: 10px !important;
        margin: 0px;
        text-indent: 0;
        .opacity(0.5);
        .thumb {
          display: inline-block;
          float: left;
          text-align: right;
          position: relative;
          &:first-child span {
            left: 0;
            right: auto;
          }
          img {
            float: left;
            width: 110px;
            height: auto;
            border: @grey 1px solid;
          }
          span {
            position: absolute;
            bottom: -24px;
            right: 0;
            left: auto;
            font-size: 12px;
            margin-left: 0px;
            margin-right: 0px;
          }
        }
        &.active
        {
          .opacity(1);
        }
      }
    }
  }
}

.csCarousel-media {
  position: relative;
  width: 100%;
  overflow: visible !important;
  .carousel-control {
    width: 5%;
    &.left {
      background-image: none;
    }
    &.right {
      background-image: none;

    }
  }
  .carousel-inner {
    .carousel-item {
      .thumbImg {
        display: block;
        width: 42%;
        margin: 0px auto;
        vertical-align: middle;
        span {
          left: 5px;
          clear: both;
          text-align: left;
          display: block;

        }
        &:first-child {
          text-align: left;
        }
        img {
          width: auto !important;
          max-height: 400px !important;
          float: left;
        }
      }
    }
  }
  &.carousel-thumbnails {
    margin-bottom: 210px;
    .carousel-indicators {
      margin-bottom: -190px !important;
      overflow-x: inherit;
      li {
        margin-right: 5px !important;
        text-indent:0;
        .opacity(0.5);
        img {
          width: 110px;
          height: auto;
          border: @grey 1px solid;
        }
        &.active
        {
          .opacity(1);
        }
      }
    }
  }
}

.carousel-thumbnails {
  .carousel-indicators {
    li {
      margin-right: 10px;
      img {
        width: 110px;
        height: auto;
        border: @grey 1px solid;
      }
    }
  }
}

.csMedia__image{
    position: relative;
    overflow: hidden;
    .csMedia-item {
      position: relative;
      padding-top: 100%;
      .thumbImg {
        img {
            width: 100%;
            height: auto;
            padding: 0;
            margin: auto;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
        }
      }
    }
  }
