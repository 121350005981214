
.csWidget-wrapper {
  margin-bottom: 30px; /* widget wraper */
}

.csWidget__list{
  max-height: 200px;
  overflow-y: auto;
  li{
    &.active{
      label{
        .csWidget__label{
          color: @blue;
        }
      }
    }
  }
}
/* ==================  overWrite start  ============================*/

/* Custom Iframe  Interactive **/
.ratio {
  display: none;
}

.csiframe_wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto 20px auto;
  background: #CCC;
  clear: both;
  .csiframe {
    position: relative;
    .ratio {
      display: block;
      width: 100%;
      height: auto;
    }
    iframe.inContentIFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
/* Custom Iframe  Interactive **/


