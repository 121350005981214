
/* custom css start*/
.csNested-list ul {
  padding-left: .2rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  li {
    padding-left: .6rem;
    padding-bottom: .3em;
    &:before {
      content: "\f105";
      font-size: 14px;
      font-family: fontawesome;
      margin-right: 8px;
      color: @darkGrey;
    }
    a {
      color: @darkGrey;
    }
    strong {
      font-weight: normal;
    }
    ul {
      padding-left: 1.5rem;
    }
    &.level-2 {
      padding-left: 2.5rem;
    }
    &.level-3 {
      padding-left: 3.5rem;
    }
    &.level-4 {
      padding-left: 4.5rem;
    }
  }
}

.csFigCaption {
  padding: 8px 0px;
  font-size: 12px;
}

.csMedia-left {
  width: 50%;
  float: left;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.csMedia-right {
  width: 50%;
  float: right;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  .csFigCaption {
    text-align: right;
  }
}
.csMedia-right,
.csMedia-left {
  figure {
    margin-bottom: 11px;
  }
}

.csPre-code {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto;
  background: @uiThemeColor;
  padding: 15px;
  margin: 20px 0 40px;
  border: 1px @black solid;
  .round-borders(2px);
  color: @white;
  display: block;
  font-size: 13px;
}

ul{
  &.csDefault-list {
    display: block;
    .padding-start(20px);
    .margin-before(1em);
    .margin-after(1em);
    .margin-end(0em);
    li {
      list-style: disc;
      p {
        margin-bottom: 0;
      }
      ul {
        padding-left: 2rem;
        margin: 5px 0 10px 0;
      }
      ol {
        padding-left: 2rem;
        margin: 5px 0 10px 0;
        li {
          list-style-type: decimal;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


ol{
  &.csDefault-list {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 20px;
    li {
      list-style: decimal;
      p {
        margin-bottom: 0;
      }
      ul {
        padding-left: 2rem;
        margin: 5px 0 10px 0;
        li {
          list-style-type: disc;
          p {
            margin-bottom: 0;
          }
        }
      }
      ol {
        padding-left: 2rem;
        margin: 5px 0 10px 0;
        li {
          list-style-type: decimal;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


.csTable--theme-xmas {
  p {
    margin: 0;
  }
  caption {
    caption-side: top;
  }

  col {
    background-color: @lightGrey
  }
  col.style-1, th.style-1 {
    background-color: darken(@style-color-1, 5%) !important;
    border-color: darken(@style-color-1, 10%)
  }
  col.style-2, th.style-2 {
    background-color: darken(@style-color-2, 5%) !important;
    border-color: darken(@style-color-2, 10%)
  }
  col.style-3, th.style-3 {
    background-color: darken(@style-color-3, 5%) !important;
    border-color: darken(@style-color-3, 10%)
  }

  tr.style-1 {
    td, th {
      background: @style-color-1;
      border-color: darken(@style-color-1, 5%);
    }
  }
  tr.style-2 {
    td, th {
      background: @style-color-2;
      border-color: darken(@style-color-2, 5%);
    }
  }
  tr.style-3 {
    td, th {
      background: @style-color-3;
      border-color: darken(@style-color-3, 5%);
    }
  }

  td.style-1,
  th.style-1 {
    background: @style-color-1;
    border-color: darken(@style-color-1, 5%);
  }
  td.style-2,
  th.style-2 {
    background: @style-color-2;
    border-color: darken(@style-color-2, 5%);
  }
  td.style-3,
  th.style-3 {
    background: @style-color-3;
    border-color: darken(@style-color-3, 5%);
  }

}

.csArticle-share {
  .twitter,
  .facebook,
  .googleplus,
  .pinterest
  {
    a {
      color: @white;
      &:hover {
        color: @white;
      }
    }
  }
  .fa {
    margin-right: 8px;
  }
  .info {
    color: @grey;
  }
}
/* custom css end*/

/* ==================  overWrite start  ============================*/
.pswp {
  z-index: 999999999;
}

.shariff-button {
  &.btn {
    padding: 0;
    a {
      padding: .8rem 1.5rem;
      display: inline-block;
    }
  }
}

.mdb-lightbox{
  figure {
    margin-bottom: 11px;
  }
}

.view {
  figure {
    margin: 0 0 0rem 0;
  }
}
.carousel-thumbnails {
  margin-bottom: 115px;
  &.cs-slider {
    .carousel-item {
      height: 100%;
    }
    .carousel-control {
      background-image: none;
      height: 40px;
      top: 50%;
    }
    .carousel-inner {
      .carousel-item {
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
        .video {
          max-width: 100%;
          width: 100%;
          height: auto;
          float: left;
        }
      }
    }
  }
  .carousel-indicators {
    max-width: 100%;
    width: inherit;
    white-space: nowrap;
    overflow-x: hidden;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: -105px !important;
    li {
      margin-right: 0px;
      position: relative;
      .fa-play {
        background: @black;
        position: absolute;
        left: 50%;
        right: 50%;
        top: 15px;
        width: 30px;
        height: 30px;
        display: block;
        margin-left: -15px;
        padding: 0;
        border: none;
        .round-borders(15px);
        text-indent: 0px;
        color: @white;
        &:before {
          position: absolute;
          top: 9px;
          left: auto;
          right: auto;
          margin: auto;
          font-size: 13px;
          margin-left: -3px;
        }
      }
    }
  }
}

.input-group-btn {
  span:not(:first-child) {
    .btn {
      margin-left: -1px;
    }
  }
}
.tooltip-input-group-btn {
  padding-top: 6px;
  vertical-align: top;
  >span {
    display: inline-block;
    vertical-align: top;
    >span{
      display: inline-block;
      vertical-align: top;
    }
    a {
      &.btn {
        &.btn-sm {
          margin: 0;
        }
      }
    }
  }
}


/* ---- Code Snippet Code ---- */
.snippet-container {
  .snippet-wrap {
    .sh_sourceCode {
      border-radius: 0;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }
}
.csPage_navigation{
   margin: 1rem 0 1rem 0;
  .page_link, .previous_link, .next_link{
    position: relative;
    float: left;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    color: @font-base-color;
    text-decoration: none;
    background-color: @white;
    &.active_page{
      background-color: @lighterGrey;
      color: @black;
    }
    &.disable{
      color: @lightGrey;
    }
  }
}

