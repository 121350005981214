.csTopics {
  .box-sizing;
  /*csTopics__sorting*/
  .csTopics__sorting {
    margin: 0 0 30px 0;
    width: 100%;

    li {
      width: 100%;
      display: block;
      font-size: 18px;
      padding: 0px 0;

      a {
        cursor: pointer;
        padding: 2px 0;
        display: block;
        color: @darkGrey;
        &:hover {
          color: @darkGrey;
          text-decoration: underline;
        }
      }

      &.csTopics__sorting-marker {
        font-size: 200%;
        margin: 20px 0 0;
        width: 100%;
        border-bottom: 1px @border-gray solid;
        width: auto;
        margin: 20px 0 10px 0;
        font-weight: 300;
      }
    }
  }
}



/* ==================  overWrite start  ============================*/
.pager {
  padding: 0;
  margin-top: 0;
  width: 100%;
  .clear;
  li {
    display: block;
    float: left;
    padding: 0;
    margin: 0 2px 5px;

    &.active {
      a {
        background-color: @lighterGrey;
        border: 1px solid @lightGrey;
        color: @black;
        &:focus {
          outline: 5px auto @stylishColor;
          border: none;
          border: 1px solid @lightGrey;
        }
      }
    }
    a {
      display: inline-block;
      padding: 6px 8px;
      font-size: 16px;
      line-height: 16px;
      margin: 0;
      cursor: pointer;
      .round-borders(2px);
      border: @lightGrey 1px solid;
      color: @black;
      background-color: @white;
      margin: 0 3px;
      .box-shadow(none);
      &:hover {
        text-decoration: none;
        background-color: @white;
        color: @black;
      }
    }
  }
}