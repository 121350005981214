
h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .8rem;
}

.clear {
  clear: both;
}

body {
  //overflow-x: hidden !important;
}

/* ---- custom css start ---- */
main {
  padding-top: 66px;
}

iframe {
  border: 0px;
}

.csEle__boxshadow-none {
  .box-shadow(none) !important;
}

.csEle__margin-none {
  margin: 0 !important;
}

.border-none {
  border: 0 !important;
}

.csEle__cursor-pointer {
  cursor: pointer;
}

i {
  &.list-title-icon {
    font-size: 20px !important;
  }
}

.csEle__position-inherit {
  position: inherit !important;
}

.csEle__position-relative {
  position: relative !important;
}

.csEle__position-fixed {
  position: fixed !important;
}

.responsive-img, .img-fluid {
  width: 100%; /* for image width 100% */
}

.hidden {
  display: none !important;
}

.light-grey-text {
  color: @midGrey;
}

.csCol-card {
  margin-bottom: 30px;
}

.csResize-verticle {
  resize: vertical !important;
}

.csOver-hidden {
  overflow: hidden !important;
}

.csKeyvisual-img-bdr {
//  border-bottom: 2px solid @darkGrey;
}


textarea:focus, input:focus, a:focus {
  outline: none;
}

/* ---- custom css end ---- */

/* ==================  overWrite start  ============================*/

.js-dropdown {
  position: absolute;
  top: 0px;
  left: 0px;
  .opacity(1);
}

.drag-target {
  display: none !important;
}

/* ---- Anchor color Start ---- */
a {
  color: @font-base-color;
  &:hover {
    color: @font-base-color;
  }
  &:visited {
    color: @font-base-color;
  }
  &:focus {
    color: @font-base-color;
  }
}

/* ---- Anchor color End ---- */

.h3-responsive {
  a {
    color: @darkGrey;
  }
}

/* ---- Pagination Start ---- */
.pagination {
  margin: 1rem 0 0 0;
  .page-item {
    &.active {
      background-color: @lighterGrey;
      color: @black;
      .page-link {
        background-color: @lighterGrey;
        color: @black;
        .box-shadow(none);
      }
    }
  }
  .page-link {
    color: @stylishColor;
    border: none;
  }
  .disable {
    float: left;
    padding: .5rem .75rem;
    cursor: not-allowed;
    color: @lightGrey;
  }
}

/* ---- Pagination End ---- */

/* ---- Button Start ---- */
.btn {
  //box-shadow: none;
  border-radius: 2px !important;
  &.btn-sm {
    padding: 0.64rem 1rem;
    font-size: 1rem;
  }

  &.img-x-them {
    .box-shadow(none);
    border: 0;
    padding: 0;
    color: @grey !important;
    .img-poll-thumb {
      margin: 5px 15px;
      .box-shadow(0 0 5px @grey);
    }
    &.active {
      .img-poll-thumb {
        color: @blue;
        .box-shadow(0 0 5px @blue);
      }
    }
  }
  &.col-xs-8{
    float: none;
  }
}

.bookMarkList {
  max-height: 200px;
  margin-bottom: 0;
  overflow-y: auto;
  li {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* ---- Button End ---- */

/* ---- Carousel Start ---- */
.carousel-control {
  .icon-next {
    background-color: @stylishColor;
    height: 40px;
    width: 34px;
    font-size: 36px;
    margin-top: -41px;
    color: #fff;
  }
  .icon-prev {
    background-color: @stylishColor;
    height: 40px;
    width: 34px;
    font-size: 36px;
    margin-top: -41px;
    color: #fff;
  }
}

/* ---- Carousel End ---- */
.progress {
  background: @white;
}

.dropup {
  .caret {
    border-bottom: 0;
  }
}

.text-xl-center {
  font-weight: 300;
}

.navbar-fixed-top {
  z-index: 999999998;
}

.md-textarea {
  margin-bottom: .3rem;
}

/* --- chrome scrollbar -- */
::-webkit-scrollbar {
  appearance: none;
  width: 5px;
  background-color: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  .round-borders(4px);
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(250, 250, 255, .5);
}

.csKeyVisual {
  .csTeaser__text {
    font-size: 1rem;
    font-weight: 300;
    color: #333;
    margin-bottom: 0.5rem;
  }
}

.csChip {
  font-size: 13px;
  color: @midGrey;
  padding: 0 12px;
  border-radius: 16px;
  background-color: @white;
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: inline-block;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  border: 1px solid @lightGrey;
  .csChip-border {
    border-left: 1px solid @lightGrey;
    padding-left: 4px;
  }
  &.csHighlight-chips {
    background-color: @lighterGrey;
    color: @midGrey;
    a {
      color: @midGrey;
      &.csChip-border {
        border-left: 1px solid @lightGrey;
      }
      &:visited {
        color: @midGrey;
      }
      &:focus {
        color: @midGrey;
      }
    }
  }
}

/******Carousel*****/

.csCarousel-fullpage {
  .csCarousel-caption-right {
    top: 0px;
    background: #e4f3ea;
    width: 40%;
    float: right;
    right: 0%;
    left: auto;
    bottom: 0px;
    color: @black;
    text-shadow: none;
    .animated {
      position: absolute;
      text-align: center;
      top: 50%;
      margin-top: -60px;
      bottom: 0;
      left: 10%;
      right: 10%;
    }
  }
  .carousel-item {
    z-index: 2;
    .card {
      .card-block {
        padding: 1rem 0 1rem 0;
      }
    }
  }

  .carousel-indicators {
    bottom: -20px;
    z-index: 0;
    li {
      border: 2px solid @stylishColor;
      width: 14px;
      height: 14px;
      margin: 1px 2px 1px 1px;
    }
    .active {
      background-color: @grey;
      width: 16px;
      height: 16px;

    }
  }
  .carousel-control {
    width: 0px;
    &.left {
      background-image: none;
    }
    &.right {
      background-image: none;
    }
    .icon-next {
      margin-top: -21px;
    }
    .icon-prev {
      margin-top: -21px;
    }
  }

  .carousel-inner {
    overflow: visible;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.no-border {
  border: none !important;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search-md],
input[type=search] {
  height: 3rem;
  .box-sizing(border-box);
}

/* csFilter */
.csFilter__label {
  line-height: 3rem;
}

.csText-size-sm {
  font-size: 0.8em;
}

.csText-size-md {
  font-size: 0.9em;
}

ul {
  li {
    font-weight: 300;
  }
}

/* List style */
.list-default {
  padding-left: 25px;
  li {
    list-style-type: disc;
    margin-bottom: 5px;
  }
}

/* Utilities spacing */

.pt-05 {
  padding-top: 0.5rem !important;
}

.pr-05 {
  padding-right: 0.5rem !important;
}

.pb-05 {
  padding-bottom: 0.5rem !important;
}

.pl-05 {
  padding-left: 0.5rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

/* collapse: caret rotation */
[data-toggle="collapse"] {
  cursor: pointer;
}

[data-toggle="collapse"][aria-expanded="true"] > .fa-caret-right {
  .rotation(90deg);
}

/* Poll  */
.img-poll[data-toggle="buttons"] .img-x-them.btn {
  cursor: pointer;
}
.img-poll .img-x-them.btn {
  cursor: auto;
}

.trns-135{
  transform: rotate(135deg);
  margin-top: 4px;
}


p[data-click-handler]{
  cursor: pointer;
}
