[class^="fa-"], [class*=" fa-"]{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    margin: 0 0 0 0;
  }
}

/* icomoon custom icons */
@font-face {
  font-family: 'icomoon';
  src:url('../fonts/roboto/icomoon.eot?-gl836m');
  src:url('../fonts/roboto/icomoon.eot?#iefix-gl836m') format('embedded-opentype'),
  url('../fonts/roboto/icomoon.woff?-gl836m') format('woff'),
  url('../fonts/roboto/icomoon.ttf?-gl836m') format('truetype'),
  url('../fonts/roboto/icomoon.svg?-gl836m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-issue:before{
  content: '\e900';
  font-family: 'icomoon';
  vertical-align: middle;
}

.fa-file-none-o{
  &:before{
    font-family: FontAwesome;
    content: "\f0f6";
  }
}
.fa-file-slideshow-o{
  &:before{
    content: '\e901';
    font-family: 'icomoon'
  }
}