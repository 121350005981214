  /* ==================  overWrite start  ============================*/
  header {
    .navbar-brand {
      img {
        height: 30px;
      }
    }
    .avatar-icon{
      border: @white solid 1px;
      .round-borders(50%);
      width: 30px;
      display: inline-block;
      height: 30px;
      line-height: 27px;
      margin-top: 5px;
    }
    .navbar {
      &.navbar-dark {
        .navbar-nav {
          .nav-link {
            &.active {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
          .nav-item{
              .subMenuCurrent{
                background-color: #f5f5f5;
              }
            &.navMoreLi{
              & > a.subMenuCurrent{
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }

    }
  }
  /* ---- Dropdown Start ---- */
  .dropdown {
    .dropdown-menu {
      padding: 0.5rem 0.7rem 0.5rem 0.5rem;
      .dropdown-item {
        padding: 6px;
        color: @darkGrey !important;
        &:hover {
          &:extend(.csEle__boxshadow-none);
          color: @darkGrey !important;
        }
        ul {
          margin-bottom: 0rem;
        }
      }
      .navDropdown {
        position: relative;

    
        a.dropdown-item {
          padding-right: 15px!important;
        }

        .dropdown-toggle-right {
          &:after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-right: 0;
            margin-left: .25rem;
            vertical-align: middle;
            content: "";
            right: 0;
            top: 1.1rem;
            position: absolute;
            border-top: .3em solid transparent;
            border-right: .3em solid transparent;
            border-left: .3em solid;
            border-bottom: .3em solid transparent;
          }
        }
        .dropdown-menu {
          position: absolute;
          top: 0;
          left: 100%;
        }
      }
    }
  }
  /* ---- Dropdown End ---- */

  /* ---- Breadcrumb Start ---- */
  .breadcrumb {
    background-color: @white;
    padding: .75rem 0rem !important;
    li:nth-child(2) {
      &:before{
        content: "";
        padding-left: 0px;
        padding-right: 0px;
        float:left;
      }
    }
    span {
      float: left;
      margin-right: 10px;
      color: @lightGrey;
    }
    a {
      color: @darkGrey;
    }
  }
  /* ---- Breadcrumb End ---- */


  /* ---- Mobile Navigation Start ---- */
  .button-collapse {
    font-size: 1.5rem;
  }
  /* ---- Mobile Navigation End ---- */

  /* ---- Navigation Search Start ---- */
  .navbar {
    .round-borders(0px);
     form input[type=search] {
       .box-sizing(content-box);
       &:focus:not([readonly]) {
         border-bottom: 1px solid @white;
         .box-shadow(0 1px 0 0 @white);
       }
    }
  }


  form input[type=search] {
    background-color: transparent;
    .round-borders(0px);
     outline: none;
    .transition(all, .3s);
    -webkit-appearance: none;
    &:focus:not([readonly]) {
      border-bottom: 1px solid @selectblue;
      .box-shadow(0 1px 0 0 @selectblue);
    }
  }
  /* ---- Navigation Search End ---- */
