/* custom css start*/
.csBike-icons {
  background: url("../img/cenbike_ico_sprite.png") 0 0 no-repeat;
  width: 30px;
  height: 20px;
  margin-right:1rem;
  display: inline-block;
  &.frame {
    background-position: 0 -4px;
  }
  &.color {
    background-position: 0 -32px;
  }
  &.break {
    background-position: 0 -353px;
  }
  &.fork {
    background-position: 0 -92px;
  }
  &.weight {
    background-position: 0 -778px;
  }
  &.grips {
    background-position: 0 -236px;
  }
  &.inner-tubes {
    background-position: 0 -574px;
  }
  &.chain {
    background-position: 0 -458px;
  }
  &.handlebar {
    background-position: 0 -205px;
  }
  &.tyre-front {
    background-position: 0 -516px;
  }
  &.tyre-rear {
    background-position: 0 -516px;
  }
  &.rear-derrailleur {
    background-position: 0 -265px;
  }
  &.shifters {
    background-position: 0 -324px;
  }
}

.csProduct__btn-overley {
  background-color: rgba(0,0,0,0.5);
  padding: 0;
  .round-borders(0px);
  width: 34px;
  height: 30px;
  border: 1px solid @grey;
  i {
    color: @white;
  }
}

.csSlide-icon {
  &:before {
    content: '\e901';
    font-family: 'icomoon';
  }
}

.csProduct-additional__content-modal {
  .csProduct-additional__img {
    width: 50%;
    margin: 0px 20px 20px 0px;
    .box-sizing;
  }
}

.csDownload-list {
  li {
    margin-bottom: .6rem;
    a {
      color: @black;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
table{
  p{
    margin-bottom: 0;
  }
}
.accordion{
  border: 1px solid #eee;
  .panel{
    padding: 0px;
    &:last-child{
      border: 1px solid @white;
    }
    .panel-heading{
      color: @darkGrey !important;
      .panel-title{
        margin-bottom: 0px;
        a{
          color: @darkGrey !important;
          padding: .8rem .8rem;
          display: block;
        }
      }
    }
  }
  .panel-collapse{
    padding:0 0.8rem;
    .csCollaps-inner{
      padding: .5rem .8rem;
    }
  }
}



