.sitetree{
  .node {
    cursor: pointer;
    circle {
      fill: #fff;
      stroke: steelblue;
      stroke-width: 1.5px;
    }
    text {
      font: 10px sans-serif;
    }
  }

  .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
  }
}
