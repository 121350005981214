/* Footer css Start */
footer {
  font-size: @basefont;
  /* custom css start*/
  &.page-footer {
    padding-top: 10px;
  }
  ul{
    &.csFooter-link {
      list-style: none;
      li {
        float: left;
        margin: 0 20px 20px 0;
      }
    }
  }
  .csFooter-language-switcher {
    margin-bottom: 10px;
    .lg-label {
      color: @grey;
      .selected {
        color: @white;
      }
    }
    .btn {
      .box-shadow(none);
      padding: 0rem 0.5rem 0.1rem 0.2rem;
      line-height: 1.5;
    }
    .dropdown-menu {
      padding: 0;
      .dropdown-item {
        color: @midGrey;
        &.active {
          color: @white;
        }
      }
    }
    /* custom css end*/
  }
  .footer-copyright {
    font-size: @basefont - 0.3rem;
  }
}
/* Footer css End */