.csLike-header {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  .csLike__percent-bar {
    margin: 10px 0;
    width: 100%;
    overflow: hidden;
    .csLike__bar {
      float: left;
      display: inline-block;
      height: 3px;
      background: @grey;
    }
    .csDislike__bar {
      float: left;
      display: inline-block;
      height: 3px;
      background: @grey;
    }
  }
}

.csTeaser__like
{
  margin-right: 1px;
  .icon {
    &.fa {
      color: @grey !important;
    }
  }
}
.fa{
  &.fa-thumbs-down
  {
    color: @grey !important;

  }
  &.fa-thumbs-up
  {
    color: @grey !important;

  }
}

.csTeaser__footer{
  .csTeaser__like
  {
    display: inherit;
  }
}



.js-csCommentLikeDislike{
  .list-inline-item{
    position: relative;
    .overlay-click-prevent{
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left: 0;
      cursor: not-allowed;
    }
  }
}
