/* custom css start*/
.user-login {
  .btn {
    margin: 6px 6px 6px 0px;
  }
}

.internal {
  &:after {
    content: '\00a0\f105\00a0\00a0';
    display: inline-block;
    text-rendering: auto;
    font: normal normal normal 14px/1 FontAwesome;
    position: relative;
  }
}
/* custom css end*/
