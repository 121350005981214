/* csSelected - hierarchical */

select.csSelected{
  display: none;
}
.csSelected {
  display: inline-block;
  border-bottom: 1px solid #ccc;
  position: relative;
  height: 3rem;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;

  &.csSelected--isOpen {
    >.csSelected__icon {
      // .transform(rotate(-90deg));
    }
  }

  >.csSelected__icon {
    position: absolute;
    bottom: .9rem;
    font-size: 10px;
    right: 0;
    z-index: -1;
  }

  .csSelected__val {
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 3rem;
    width: 100%;
    color: #000;
  }

  >.csSelected__val {
    position: relative;
    span {
      margin: 0 5px 0 0;
      &:after {
        content: ',';
        display: inline-block;
      }
      &:last-child:after{
        display: none;
      }
    }
  }


  .csSelected__result {
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 0.125rem 0 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    position: absolute;
    overflow: auto;
    width: 100%;
    top: 45px;
    display: none;
    cursor: default;
    z-index: 9;

    .csSelected__label {
      display: block;
      padding: 3px 0;
      margin: 0;
      font-size: 1rem;
      color: #4B515D;

      line-height: 1rem;
      min-width: 160px;
      height: auto;
      font-weight: 300;
    }

    .csSelected__icon {
      width: 20px;
      height: 20px;
      text-align: center;
      position: absolute;
      left: 0px; top: 5px;
      cursor: pointer;
    }

    li {
      margin: 0;
      padding: 0; 
      padding-top: 5px;
      padding-bottom: 5px;
      position: relative;
      
      &.active {
        padding-bottom: 0;
        >.csSelected__icon{
          .transform(rotate(90deg));
        }
      }
    }

    /* 2nd lvl */
    ul {
      margin: 0;
      display: none;
      padding: 2px 0 0 60px;
    }

    /* checkbox*/
    input[type="checkbox"] + label {
      padding-left: 0;
      margin-left: 30px;
      &:before {
        left: -30px;
        margin: 0;
      }
    }
    span.csSelected__icon + input[type="checkbox"] + label {
      margin-left: 60px;
    }
  }
}
