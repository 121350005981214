
/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
    ---------------------------------------------------- */

.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, @start), color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
    @start,
    @stop);
  background: -moz-linear-gradient(center bottom,
    @start 0%,
    @stop 100%);
  background: -o-linear-gradient(@stop,
    @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}


.round-borders(@radius: 2px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
  -khtml-border-radius: @radius;
}

.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  .background-clip(padding-box);
  -webkit-border-top-left-radius: @topleft;
  -moz-border-radius-topleft: @topleft;
  border-top-left-radius: @topleft;
  -webkit-border-top-right-radius: @topright;
  -moz-border-radius-topright: @topright;
  border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -moz-border-radius-bottomright: @bottomright;
  border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -moz-border-radius-bottomleft: @bottomleft;
  border-bottom-left-radius: @bottomleft;
}

.opacity(@opacity: 0.5) {
  @opperc: @opacity * 100;
  opacity: @opacity;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
}

.transition-duration(@duration: 0.2s) {
  -webkit-transition-duration: @duration;
  -moz-transition-duration: @duration;
  -o-transition-duration: @duration;
  transition-duration: @duration;
}

.transform(...) {
  -webkit-transform: @arguments;
  -moz-transform: @arguments;
  -ms-transform: @arguments;
  -o-transform: @arguments;
  transform: @arguments;
}

.padding-start( @arguments) {
  -moz-padding-start: @arguments;
  -webkit-padding-start: @arguments;
  -khtml-padding-start: @arguments;
  -o-padding-start: @arguments;
  padding-start: @arguments;
}

.margin-end( @arguments) {
  -moz-margin-end: @arguments;
  -webkit-margin-end: @arguments;
  -khtml-margin-end: @arguments;
  -o-margin-end: @arguments;
  margin-end: @arguments;
}

.margin-after( @arguments) {
  -moz-margin-after: @arguments;
  -webkit-margin-after: @arguments;
  -khtml-margin-after: @arguments;
  -o-margin-after: @arguments;
  margin-after: @arguments;
}
.margin-before( @arguments) {
  -moz-margin-before: @arguments;
  -webkit-margin-before: @arguments;
  -khtml-margin-before: @arguments;
  -o-margin-before: @arguments;
  margin-before: @arguments;
}

.rotation(@deg:5deg) {
  .transform(rotate(@deg));
}


.scale(@ratio:1.5) {
  .transform(scale(@ratio));
}

.transition(@property:all, @duration:0.2s, @ease:ease-out ) {
  -webkit-transition: @property @duration @ease;
  -moz-transition: @property @duration @ease;
  -o-transition: @property @duration @ease;
  transition: @property @duration @ease;
}

.box-shadow(@arguments) {
  -webkit-box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  box-shadow: @arguments;
}

.text-shadow(@arguments) {
  -webkit-text-shadow: @arguments;
  -moz-text-shadow: @arguments;
  text-shadow: @arguments;
}

.box-sizing(@sizing: border-box) {
  -webkit-box-sizing: @sizing;
  -moz-box-sizing: @sizing;
  box-sizing: @sizing;
  -ms-box-sizing: @sizing;
}

.translate(@x:0, @y:0) {
  .transform(translate(@x, @y));
}

.text-overflow(){
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}


.appearance(){
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}


.clear {
  zoom: 1;
  clear: both;
  &:before,
  &:after{
    content:"";
    display:table;
  }
  &:after { clear: both; }
}


.box-top-arrow(@left:50%, @color: #fff, @border-color: #ccc) {
  &:after, &:before {
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
  }
  &:after {
    left: @left;
    border-width: 6px;
    border-color: rgba(213, 213, 213, 0);
    border-bottom-color: @color;
  }
  &:before {
    left: @left;
    border-width: 6px;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: @border-color;
  }
}

.box-left-arrow(@top:50%, @color: #fff, @border-color: #ccc) {
  &:after, &:before {
    position: absolute;
    top: @top;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: 8px;
    left: -16px;
    margin-top: -8px;
  }
  &:after {
    border-color: rgba(213, 213, 213, 0);
    border-right-color: @color;
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: @border-color;
  }
}

.box-left-arrow-top-left(@top:50%, @left:50%, @color: #fff, @border-color: #ccc) {
  &:after, &:before {
    position: absolute;
    top: @top;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: 8px;
    left: calc(~'@{left} - 16px');
    margin-top: -8px;
  }
  &:after {
    border-color: rgba(213, 213, 213, 0);
    border-right-color: @color;
    left: calc(~'@{left} - 15px');
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: @border-color;
  }
}

.box-arrow-top-right(@top:50%, @right:50%, @color: #fff, @border-color: #ccc) {
  &:after, &:before {
    position: absolute;
    top: @top;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: 8px;
    right: calc(~'@{right} - 16px');
    margin-top: -8px;
  }
  &:after {
    border-color: rgba(213, 213, 213, 0);
    border-left-color: @color;
    right: calc(~'@{right} - 15px');
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-left-color: @border-color;
  }
}

.box-arrow-bottom-left(@bottom:50%, @left:50%, @color: #fff, @border-color: #ccc) {
  &:after, &:before {
    position: absolute;
    bottom: @bottom;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: 8px;
    left: calc(~'@{left} - 16px');
    margin-top: -8px;
  }
  &:after {
    border-color: rgba(213, 213, 213, 0);
    border-right-color: @color;
    left: calc(~'@{left} - 15px');
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: @border-color;
  }
}

.box-right-arrow-inner(@top, @color: #fff, @border-color: #ccc) {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: @top;
    right: 11px;
    width: 0;
    height: 0;
    border-right: 8px solid @color;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    z-index: 999;
  }
  &:before {
    right: 11px;
    border-right: 8px solid @color;
  }
  &:after {
    right: 10px;
    border-right: 8px solid @border-color;
  }
}

.box-right-arrow-flat-inner(@top, @color: #fff) {
	&:before {
		content: '';
		position: absolute;
		top: @top;
		right: 10px;
		width: 0;
		height: 0;
		border-right: 8px solid @color;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		z-index: 999;
	}
}


.hidden {
	visibility: hidden;
	display: block;
	position: absolute;
	top: -4000px;
	left: -4000px;
	z-index: -99;
}


// --------------------------------------------------
// Flexbox LESS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
// flex or inline-flex
.flex-display(@display: flex) {
  display: ~"-webkit-@{display}";
  display: ~"-moz-@{display}";
  display: ~"-ms-@{display}box"; // IE10 uses -ms-flexbox
  display: ~"-ms-@{display}"; // IE11
  display: @display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
.flex(@columns: initial) {
  -webkit-flex: @columns;
  -moz-flex: @columns;
  -ms-flex: @columns;
  flex: @columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
.flex-direction(@direction: row) {
  -webkit-flex-direction: @direction;
  -moz-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
  -webkit-flex-wrap: @wrap;
  -moz-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
  -webkit-flex-flow: @flow;
  -moz-flex-flow: @flow;
  -ms-flex-flow: @flow;
  flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>
.flex-order(@order: 0) {
  -webkit-order: @order;
  -moz-order: @order;
  -ms-order: @order;
  order: @order;
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow: 0) {
  -webkit-flex-grow: @grow;
  -moz-flex-grow: @grow;
  -ms-flex-grow: @grow;
  flex-grow: @grow;
}

// Flex shr
// - applies to: flex itemsink factor
// <number> 
.flex-shrink(@shrink: 1) {
  -webkit-flex-shrink: @shrink;
  -moz-flex-shrink: @shrink;
  -ms-flex-shrink: @shrink;
  flex-shrink: @shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
.flex-basis(@width: auto) {
  -webkit-flex-basis: @width;
  -moz-flex-basis: @width;
  -ms-flex-basis: @width;
  flex-basis: @width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
.justify-content(@justify: flex-start) {
  -webkit-justify-content: @justify;
  -moz-justify-content: @justify;
  -ms-justify-content: @justify;
  -ms-flex-pack: @justify;
  justify-content: @justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
.align-content(@align: stretch) {
  -webkit-align-content: @align;
  -moz-align-content: @align;
  -ms-align-content: @align;
  -ms-flex-align: @align;
  align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
.align-items(@align: stretch) {
  -webkit-align-items: @align;
  -moz-align-items: @align;
  -ms-align-items: @align;
  align-items: @align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
.align-self(@align: auto) {
  -webkit-align-self: @align;
  -moz-align-self: @align;
  -ms-align-self: @align;
  align-self: @align;
}

.flex-expand(@grow: 1, @shrink: 1, @base: auto) {
  .flex-display();
  .flex(@grow @shrink @base);
}

.flex-center-content() {
  .flex-expand();
  .flex-direction(column);
  .align-items(center);
  .justify-content(center);
}
// END Flexbox




/* =============     new     ================= */