.cs_cookies {
  margin-top: 30px;
  .navbar-fixed-top {
    top: 30px;
  }
}
.csCookies-wraper {
  position: fixed;
  top: 0px;
  min-height: 31px;
  background-color: @blue;
  color: @white;
  width: 100%;
  padding: 6px;
  font-size: 12px;
  z-index: 99999;
  a {
    color: @white;
    text-decoration: underline;
  }
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .csCookies-close {
    margin: 4px 5px 0px 0px;
  }
  display: none;
}
