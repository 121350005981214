
/* color */
@grey: #999999;
@btnGrey: #6d6d6d;
@white: #ffffff;
@black:#000000;
@darkGrey:#333;
@midGrey:#757575;
@lightGrey:#ccc;
@lighterGrey:#f1f1f1;

@gold: gold;
@navBg: #6d6d6d;
@stylishColor: #4B515D;
@uiThemeColor:#565d6b;

@red: #cf5f4c;
@blue: #0275d8;
@selectblue: #4285F4;
@bloodred:#C00;

/* font size */
@basefont:1rem;

@tl: 0.6s;

/* custome color code*/
@style-color-1: #F5F5F5;
@style-color-2: #DBE9F4;
@style-color-3: #E0F7DF;

@color-1: #2F5FAB; /* dark-blue */
@color-2: #54C1FA; /* light-blue */
@color-3: #F9D04E; /* yellow */
@color-4: #F03D58; /* red */
@color-5: #904BCF; /* purple */
@color-6: #3EBE22; /* green */


@ui-font-300: 'SourceSansPro-Light';
@ui-font-400: 'SourceSansPro';
@ui-font-600: 'SourceSansPro-Semibold';
@ui-font-700: 'SourceSansPro-Bold';


@color-border: #e0e0e0;
@ci-font-gray-dark: #1d1d1d;
@ci-blue: #476cb6;
@ci-font-gray: #5b5a5a;
@color-content: #fff;
@border-gray: #d6d6d6;
@font-black: black;
@font-gray: #505050;
@loader-color:#565d6b;
@font-base-color:#373a3c;
