.csMonsterSlider {

  .carousel-inner + .navbar,
  .carousel-inner ~ .carousel-control + .navbar{
    margin-top: -12px;
    padding: 0;
  }

  .carousel-control.right {
    background: transparent;
    right: -10px;
    width: 60px;
  }

  .carousel-control.left {
    background: transparent;
    left: -10px;
    width: 60px;
  }

  .navbar .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    text-indent: 0;
  }

  .carousel-indicators {
    position: relative;
    width: 100%;
    left: auto;
    margin: 0;
    padding: 6px;
    bottom: auto;
    display:block!important;
  }

  .carousel-indicators li {
    width: auto;
    height: auto;
    border-radius: 2px;
    border: none;
    margin: 0;
    font-weight: 400;
    display: none;

    &.active {
      display: block;
      background: transparent!important;

      .nav-link {
        background: transparent!important;
      }
    }
  }

}

@media screen and (min-width: 1024px){
  .csMonsterSlider .carousel-indicators li {
    display: block;

    &.active {
      background-color: rgba(158, 158, 158, 0.2)!important;
    }
  } 
}
