.csCard-title--nobreak{
  .text-overflow();
  line-height: 1.2em;
}

.csCard__thumbcon {
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  font-size: 50px;
  color: #ccc;
  display: block;
}

/* Flex grid view */
.csGrid--flex{
  .flex-display();
  .flex-direction();
  .flex-wrap(wrap);
  .csSpacer{
    margin-top:15px;
    margin-bottom: 15px;
  }
}

/* List view */
.csGrid--list {
  .flex-direction(column);
  > div {
    width: 100%!important;
    article.card {
      .flex-display;
      padding-left: 15px;
    }
    article >a {
      float: left;
      padding: 0;
      width: 120px;
      height: 120px;
      margin: 1rem 0;
      border: 1px solid @lightGrey !important;
    }
    .csCard__ctrl {
      position: absolute;
      bottom: 10px;
      left: 19px;
    }
    .card-footer {
      width: ~"calc(100% - 120px)";
      border: none;
      position: relative;
      float: left;
      padding-bottom: 3rem;
    }
  }
}

.csCard__ctrl {
  margin-bottom: 5px;

  .csCard__ctrl__item {
    margin: 0 10px 0 0;
  }
  .dropdown-menu {
    padding: 20px;
    min-width: 320px;
    
    .select-dropdown {
      min-width: auto;
      padding: 0;

      li {
        clear: both;
        color: @darkGrey;
        cursor: pointer;
        line-height: 1.5rem;
        width: 100%;
        text-align: left;
        text-transform: none;
        span {
          font-size: 1rem;
          color: @darkGrey;
          display: block;
          padding: 0.7rem 0.7rem;
        }
      }
    }
    .csWidget-wrapper{
      margin-bottom: 0px;
    }
  }
  .list-inline-item{
    position: relative;
    .overlay-click-prevent{
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left: 0;
      cursor: not-allowed;
    }
  }
  
}

/* csCard List */
.csCard__list {

  article {
    margin-bottom: 30px;
    &.csDlBasket__item{
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
  .card-title {
    font-size: 20px;
    line-height: 26px;
    padding: 4px 0 0 0;

    .fa {
      font-size: 18px;
      position: relative;
    }
    small{
      font-size: 70%;
      color:@btnGrey;
      .fa {
        top:2px;
      }
    }
  }
}

.col-md-6 .csCard__list article:last-child,
.col-lg-4 .csCard__list article:last-child {
  border-bottom:0;
  margin-bottom:0;
  padding-bottom:0;
}


/* override mdb footer font-size */
.card .card-footer {
  font-size: 1rem;
}


/* override nested cards */
.card{
  .card{
    .box-shadow(none);
     margin: 0;
    &.card-block{
      padding:0;
    }
    .card-block{
      padding:0;
      .dropdown-item{
        padding: 0;
        &:hover{
          background-color: transparent;
        }
      }
    }
  }
  .card-block{
    h3{
      font-weight: 300;
    }
  }
  .csWidget-wrapper{
    margin-bottom: 0;
  }

  &.quick-poll{
    .card-block{
      padding:1.25rem;
    }
  }

  .csCard__list{
    .card{
      &.quick-poll{
        .card-block{
          padding-left:0 !important;
          padding-right:0 !important;
        }
        .img-poll-thumb{
          .card-block{
            padding:1.25rem !important;
          }
        }
      }
    }
  }
}

.csLabel{
  position: absolute;
  top:0.8rem;
  left: 1rem;
  font-size: 0.8rem;
  color: @white;
  z-index: 2;
  text-shadow: 1.5px 1px @darkGrey;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.3rem 0.6rem;
  a{
    color: @white;
  }
  &.color-1{
    background: @color-1;
  }
  &.color-2{
    background: @color-2;
  }
  &.color-3{
    background: @color-3;
  }
  &.color-4{
    background: @color-4;
  }
  &.color-5{
    background: @color-5;
  }
  &.color-6{
    background: @color-6;
  }
}

/* Download-Basket */
.csDlBasket__item, article.csDlBasket__item {
  border-bottom:1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:last-of-type{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .csDlBasket__item-title {
    padding: 3px 1.2em 4px 0;
    display: block;
    margin: 0;
  }

  .csDlBasket__remove-btn {
    position: absolute;
    top: 0;
    right: 20px;
  }
}
