.csBtn__sm {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: @black;
  .opacity(.4);
  font-size: 1.1rem;
  &:hover {
    .opacity(.6);
  }
}

/* poll css */

/* ======================== Overwrite end ===================== */

/* ---- Alerts & Error Message Start ---- */
.message {
  padding: 5px 15px;
  border-radius: 2px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.message_error {
  color: @white !important;
  background-color: @bloodred;
  display: block;
  float: left;
  width: 100%;
}
/* ---- Alerts & Error Message End ---- */

.alert {
  padding: 5px 15px;
  ul{
    margin: 0;
    padding: 0;
  }
}
/* ---- Select style ---- */
.dropdown-content,
.dropdown-menu  {
  z-index: 998;

  & >li {
    &.disabled {
      display: none;
    }
    &:hover {
      background-color: #eee;
    }
    span {
      font-size: 1rem;
      color: @darkGrey;
      display: block;
      padding: 0.7rem 0.7rem;
    }
  }
}

/* ---- Select Dropdown Start ---- */
.select-wrapper {
  .select-wrapper {
    border: 0;
    padding-right: 0;
    span.caret{
      right:0px;
    }
  }
  ul {
    top: 3rem !important;
    max-height: 200px;
    overflow-y: auto;
    width:100% !important;
    max-width: inherit;
    li{
      &.selected{
        background-color: @lighterGrey;
      }
    }
  }
  input {
    &.select-dropdown {
      border-bottom: 0;
      padding-right: 15px;
      .text-overflow();
      border-bottom: 1px solid @lightGrey;
      box-sizing: border-box;
    }
  }
}
/* ---- Select Dropdown Start ---- */

input[type=text] {
  &.error {
    border-bottom: 1px solid red;
    box-shadow: 0 1px 0 0 red;
  }
}

input[type=password] {
  &.error {
    border-bottom: 1px solid red;
    .box-shadow(0 1px 0 0 red);

  }
}

input[type=email] {
  &.error {
    border-bottom: 1px solid red;
    .box-shadow(0 1px 0 0 red);
  }
}

.error {
  [type="checkbox"] + label:before {
    border: 2px solid red;
  }
  [type="checkbox"]:checked + label:before {
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid red;
    border-bottom: 2px solid red;
  }
  [type="radio"]:not(:checked) + label:before {
    border: 2px solid red;
  }
}
/* --------- Input Error Color End --------- */

.emailType, .phoneType, .otherType {
  .select-wrapper {
    margin-top: 6px;
  }
}

.shariff{
  .info{
    border: 0
  }
}

legend{
  font-weight: 300;
}


// Registration form
.select-wrapper.error{
  input.select-dropdown{
    border-bottom: 2px solid red;
  }
}
.alert-error {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
}


.dropdown.dropdown-above.open > .dropdown-menu{
  top: auto;
  bottom: 100%;
}
