/* ### video.less ### */

video {
  display: block;

  width: 100%;
}

/* show quality buttons */
.videoplayer.is-mouseover {
  .qualbuttons {
    display: block;
  }
}

.video {
  width: 100%;
  .box-sizing;

}
.article-video {
  video {
    width: 100%;
    max-width: 760px;
    height: 100%;
    max-height: 428px;
  }
}
.qualbuttons {
  display: none!important;
}

.flowplayer .fp-speed {
  display: none!important;
}

.box-sizing{
  box-sizing: border-box;
}

/* Flowplayer quality selctor */
.flowplayer .fp-quality-selector{transition:.2s ease-in;box-sizing:border-box;-moz-box-sizing:border-box;position:absolute;right:10px;bottom:40px;width:0;overflow:hidden;font-size:12px;font-weight:bold;}
.flowplayer .fp-quality-selector li{display:block;list-style:none;background-color:#333;background-color:rgba(0, 0, 0, 0.65);margin:5px;padding:5px;border-radius:0px;color:#fff;text-shadow:0 0 2px #000;overflow:hidden;text-align:center;}
.flowplayer .fp-quality-selector li.active{background-color:#5F97C5;background-color:rgba(47, 95, 171, 0.8);cursor:default;}
.flowplayer .fp-quality-selector li.active:hover{background-color:#5F97C5;background-color:rgba(47, 95, 171, 1)}
.flowplayer .fp-quality-selector li:hover{background-color:#444;background-color:rgba(0, 0, 0, 0.85)}
.flowplayer.is-mouseover .fp-quality-selector{width:80px}
.flowplayer.is-finished .fp-quality-selector,.flowplayer.is-disabled .fp-quality-selector{width:0}
/*
.flowplayer .fp-quality-selector li:first-child {
  display: none;
}
*/
/* Flowplayer */
.flowplayer {
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  .box-sizing;
  font-size: 14px;
  background-color: #fff!important;

  .fp-fullscreen {
    right: 15px!important;
    top: 15px!important;
  }
}

.flowplayer .fp-timeline-tooltip {
  line-height: 100%;
}

.flowplayer .fp-time,
.flowplayer .fp-timeline-tooltip,
.flowplayer .fp-quality-selector {
  font-size: 14px!important;

}
.flowplayer .fp-controls {
  background: #383838;
  .fp-progress {
    background-color: #2F5FAB;
  }
  .fp-timeline {
    background: #ccc;
  }
  .fp-buffer {
    background: #4d4d4d;
  }
  .fp-volume {
    .fp-volumeslider {
      background: #4d4d4d;
    }
    .fp-volumelevel{
      background-color: #2F5FAB;
    }
  }
}



.flowplayer canvas {
  outline: 4px solid #fff;
  position: absolute;
  z-index: 9999;
  display: none;
}

/* embedded video*/
.video__embed {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  &.inline.right {
    margin: 0 0 10px 0;
    float: right;
    width: 48%;
    padding-top:0%;
    height: 204px !important;
  }

  &.inline.left {
    margin: 0 0 5px 0;
    float: left;
    width: 48%;
    padding-top:0%;
    height: 204px !important;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.audioplayer {
  &.left{
    float: left;
    width: 50%;
    margin: 0 15px 30px 0;
    padding: 0 15px 0 0;
    .box-sizing;

    + h3
    {
      .anchor-target
      {
        margin: 0px;
        height: 0px;
      }
    }

    .csImage__box{
      width: 100%;
      padding: 0;
    }
  }
  &.right{
    float: right;
    width: 50%;
    margin: 0 0 30px 15px;
    padding: 0 0 0 15px;
    .box-sizing;
    + h3
    {
      .anchor-target
      {
        margin: 0px;
        height: 0px;
      }
    }
    .csImage__box{
      .box-sizing;
      width: 100%;
      padding: 0;
    }
  }
  .csImage__box .rel a:before{
    display: none;
  }
  .csImage__box.center
  {
    margin:15px 0 0px 0;
  }
}

/******VimeoVdeoCode*****/

.csKeyVisual-wrapper{
  margin-bottom: 20px;
  display: block;

  .keyvisual__embed{
    position: relative;
    padding-top: 56.25%; /* 16:9 */
    //padding-top: 25px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
