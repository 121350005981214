.csLoader--them-color {
  border-color: @loader-color;
}

.csPreloader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
  .preloader-wrapper {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    height: 36px;
    width: 36px;
    top: 0;
    bottom: 0;
  }
  &.active {
    display: block !important;
  }
  .csLoader--them-color {
    border-color: @loader-color;
  }
}
.preloader-wrapper {
  &.csLoader-xs {
    width: 10px;
    height: 10px;
    .circle-clipper {
      .circle {
        border-width: 2px;
      }
    }
  }
}
