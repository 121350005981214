/* comment css*/
.csAttachment-list {
  li {
    overflow: hidden;
    .csAttachment-list-thumb {
      width: 50px;
      height: 50px;
      font-size: 1.8rem;
      text-align: center;
      border: 1px solid @lightGrey;
      img {
        .round-borders(0px);
      }
    }
    .mdb-lightbox{
      display: inline-block;
    }
  }
}

.comments-list {
  .level-2 {
    margin-left: 7rem !important;
  }
  .level-3 {
    margin-left: 9rem;
  }
  .level-4 {
    margin-left: 12rem;
  }
}

.comment-msg{
  word-wrap: break-word;
}
