
.card{
  .card{
    &.csChat-room{
      margin: -15px;
    }
  }
}

.card{
  &.csChat-room{
    .csCard-body{
      .csChat-header{
        border-bottom: 1px solid fade(@lightGrey, 60%);
      }
      .csMembers-panel-1, .csChat-1{
        position: relative;
        overflow-y: auto;
      }
      .csMembers-panel-1{
        height: 654px;
        border-right: 1px solid fade(@lightGrey, 60%);
      }
      .csChat-1 {
        height: 456px;
        padding: 1rem 0.5rem 0.5rem 0.2rem;
      }

      .csChat-list{
        width: 33.33333%;
        float: left;
        &.csNewMessageUserList{
          width: 100%;
          .csMembers-panel-1{
            border: 0;
            height: auto;
          }
          .csFriend-list{
            .csText-small{
              line-height: 40px;
            }
          }
        }
        .csFriend-list{
          margin-bottom: 0;
          li {
            border-bottom: 1px solid fade(@lightGrey, 60%);;
            padding: 0.5rem;
            position: relative;
            overflow: hidden;
            &.active {
              background-color: @lighterGrey;
            }
            &:last-of-type {
              border-bottom: none;
            }
            img {
              &.avatar {
                margin-right: 0.5rem;
              }
            }
            .cs-private-msgDelete{
              position: absolute;
              bottom: -40px;
              right: 6px;
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              opacity: 0.3;
              cursor: pointer;
            }
            &:hover, &:focus{
              .cs-private-msgDelete {
                display: block;
                bottom: 0;
                opacity: 1;
              }
            }
          }
        }
      }
      .csChat-private-box{
        width: ~"calc(100% - 33.33333%)";
        padding-left: 15px;
        padding-bottom: 1rem;
        float: left;
        .csChat-message{
          .csNewMsg{
            .csChat-body{
              background-color: #dff0d8;
            }
          }
          .csChat-body{
            width: 100%;
            position: relative;
            overflow: hidden;

            .cs-private-msgDelete{
              position: absolute;
              bottom: -40px;
              right: 0;
              -webkit-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              opacity: 0.3;
              cursor: pointer;
              i{
                padding: 0.4rem 0.6rem;
                background: @lightGrey;
                color: @white;
              }
            }

            &:hover, &:focus{
              .cs-private-msgDelete {
                display: block;
                bottom: 0;
                opacity: 1;
              }
            }
          }
          .csImg-Link{
            width: 3rem !important;
            height: 3rem !important;
            img{
              &.avatar{
                width: auto !important;
                height: auto !important;
              }
            }
          }
        }
      }

      img{
        &.rounded-circle {
          border-radius: 50%;
        }
        &.avatar {
          height: 2.5rem !important;
          width: 2.5rem !important;
        }
      }
      .csText-small {
        font-size: 0.95rem;
        width: 100%;
      }
      .csText-smaller {
        font-size: 0.75rem;
      }
      p{
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }
}

.scrollbar-light-grey::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-light-grey::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #F5F5F5; }

.scrollbar-light-grey::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

//overwrite css
.d-flex{
  display: flex !important;
}
.justify-content-between{
  justify-content:space-between !important;
  -webkit-box-pack: justify!important;
}

.badge {
  display: inline-block;
  padding: .25em;
  border-radius: 1rem;
  &.cs-dropdown-bubble{
    padding: 0.30em .60em;
    color: @white;
    line-height: 15px;
    font-size: 0.8rem;
    margin: 0;
    display: none;
    &.active{
      display: inline-block;
    }
  }
}
.csNotification{
  position: absolute;
  top: -8px;
  right:0;
  .badge{
    display: none;
    &.active{
      display: inline-block;
    }
  }
}

.privateMessage{
  .modal-body{
    .md-textarea{
      overflow-y: auto;
    }
  }
}
